import {
  DataSchema,
  IntegrationAppClient,
  Workspace,
} from '@integration-app/sdk'
import { CodeBlockElement } from 'components/CodeBlock/elements'
import { CodeParams } from 'components/CodeBlock/components'
import { CodeBlockRunButton, LoginToRun } from 'components/CodeBlock/components'
import useAuth from 'contexts/auth'
import { useEffect, useState } from 'react'
import { TbBook } from 'react-icons/tb'
import { Link } from 'react-router-dom'

import useDocs from '../../routes/Docs/components/docs-context'
import { BottomErrors } from '../BottomErrors'
import { CodeHighlighterLanguagesEnum } from '../CodeHighlighter'
import useWorkspace from '../Workspaces/workspace-context'
import {
  CodeBlockExtension,
  CodeBlockLanguageTitle,
  CodeBlockType,
  CodeExampleType,
  CodeParamType,
} from './codeBlock-types'
import { CodeLanguageSelector } from '../CodeBlock/components'
import DocLink from './DocLink'

const CodeBlockExtensions = new Map<
  CodeBlockExtension,
  {
    title: CodeBlockLanguageTitle
    language: CodeHighlighterLanguagesEnum
  }
>()
CodeBlockExtensions.set('js', {
  title: CodeBlockLanguageTitle.Javascript,
  language: CodeHighlighterLanguagesEnum.javascript,
})
CodeBlockExtensions.set('ts', {
  title: CodeBlockLanguageTitle.Javascript,
  language: CodeHighlighterLanguagesEnum.typescript,
})
CodeBlockExtensions.set('tsx', {
  title: CodeBlockLanguageTitle.React,
  language: CodeHighlighterLanguagesEnum.tsx,
})
CodeBlockExtensions.set('jsx', {
  title: CodeBlockLanguageTitle.React,
  language: CodeHighlighterLanguagesEnum.jsx,
})
CodeBlockExtensions.set('vue', {
  title: CodeBlockLanguageTitle.Vue,
  language: CodeHighlighterLanguagesEnum.jsx,
})
CodeBlockExtensions.set('http', {
  title: CodeBlockLanguageTitle.API,
  language: CodeHighlighterLanguagesEnum.http,
})
CodeBlockExtensions.set('curl', {
  title: CodeBlockLanguageTitle.curl,
  language: CodeHighlighterLanguagesEnum.bash,
})
CodeBlockExtensions.set('sh', {
  title: CodeBlockLanguageTitle.Bash,
  language: CodeHighlighterLanguagesEnum.bash,
})
CodeBlockExtensions.set('npm', {
  title: CodeBlockLanguageTitle.npm,
  language: CodeHighlighterLanguagesEnum.bash,
})
CodeBlockExtensions.set('yarn', {
  title: CodeBlockLanguageTitle.yarn,
  language: CodeHighlighterLanguagesEnum.bash,
})
CodeBlockExtensions.set('go', {
  title: CodeBlockLanguageTitle.Go,
  language: CodeHighlighterLanguagesEnum.go,
})
CodeBlockExtensions.set('python', {
  title: CodeBlockLanguageTitle.Python,
  language: CodeHighlighterLanguagesEnum.python,
})
CodeBlockExtensions.set('java', {
  title: CodeBlockLanguageTitle.Java,
  language: CodeHighlighterLanguagesEnum.java,
})
CodeBlockExtensions.set('php', {
  title: CodeBlockLanguageTitle.PHP,
  language: CodeHighlighterLanguagesEnum.php,
})
CodeBlockExtensions.set('ruby', {
  title: CodeBlockLanguageTitle.Ruby,
  language: CodeHighlighterLanguagesEnum.ruby,
})

export interface RunArgs {
  integrationApp: IntegrationAppClient
  parameters: Record<string, any>
  workspace: Workspace
}

export interface ParameterSpec {
  type: CodeParamType
  title?: string
  schema?: DataSchema
  default?: any
  readOnly?: boolean
}

export type ParametersSpec = Record<string, CodeParamType | ParameterSpec>

const LANGUAGE_HELP_ARTICLES = {
  javascript: 'overview/getting-started/front-end/javascript',
  jsx: 'overview/getting-started/front-end/react',
  curl: 'overview/getting-started/backend/rest-api',
}

export default function ExampleCodeBlock({
  variables = {},
  variablesSpec = {},
  customCodes,
  parameters: parametersSpec = {},
  showOutput = true,
  parametersExpandedByDefault = false,
  run,
  key,
}: {
  variables?: Record<string, string>
  variablesSpec?: ParametersSpec
  customCodes: CodeBlockType
  parameters?: ParametersSpec
  showOutput?: boolean
  key?: string
  parametersExpandedByDefault?: boolean
  run?: (args: RunArgs) => Promise<any>
}) {
  const { self, loginLink } = useAuth()
  const { workspace } = useWorkspace()
  const { parameters } = useDocs()

  const codeExamples = codeBlocksToCodeExamples(customCodes)

  const { testCustomerClient } = useWorkspace()
  const [output, setOutput] = useState(null)
  const [error, setError] = useState(null)
  const [running, setRunning] = useState(false)

  async function doRun() {
    if (!run) {
      return
    }

    setRunning(true)
    try {
      const result = await run({
        integrationApp: testCustomerClient,
        workspace,
        parameters,
      })
      setOutput(result ?? 'No output')
      setError(null)
    } catch (e) {
      setError(e)
    } finally {
      setRunning(false)
    }
  }

  function replaceParamsAndVariables(code: string) {
    return renderVariables(
      renderVariables(code, parameters, parametersSpec),
      variables,
      variablesSpec,
    )
  }

  useEffect(() => {
    setOutput(null)
    setError(null)
  }, [key])

  const [currentLanguage, setCurrentLanguage] = useState(
    codeExamples[0].language,
  )
  const currentCodeExample = codeExamples.find(
    (codeExample) => codeExample.language === currentLanguage,
  ) as CodeExampleType

  function onLanguageChange(value: CodeExampleType['language']) {
    setCurrentLanguage(value)
  }

  const helpArticle = LANGUAGE_HELP_ARTICLES[currentLanguage] ? (
    <DocLink
      path={LANGUAGE_HELP_ARTICLES[currentLanguage]}
      className={'inline-flex items-center text-primaryColor hover:underline'}
    >
      How to run it in your app{' '}
      <TbBook className={'ml-1 mt-0.5 w-5 h-5 stroke-1'} />
    </DocLink>
  ) : undefined

  const runNotification = self?.user?.id ? (
    <span className={'inline-block w-52 leading-tight'}>
      This code will run on behalf of your{' '}
      <Link
        to='/settings/testing'
        className={'text-primaryColor hover:underline'}
      >
        test customer
      </Link>
      .
    </span>
  ) : undefined

  return (
    <CodeBlockElement.Root className={'mt-4 mb-6'}>
      <CodeParams
        expandedByDefault={parametersExpandedByDefault}
        disabled={!self?.user?.id}
        parametersSpec={parametersSpec}
      />

      <CodeBlockElement.Section
        className={'py-1.5 bg-neutral01 items-center'}
        rightSlot={helpArticle}
      >
        <CodeLanguageSelector
          value={currentLanguage}
          onChange={onLanguageChange}
          codeExamples={codeExamples}
        />
      </CodeBlockElement.Section>

      <CodeBlockElement.Code
        code={replaceParamsAndVariables(currentCodeExample.code).trim()}
        language={currentCodeExample.language}
        copyToClipboard
      />

      {run && (
        <CodeBlockElement.Section rightSlot={runNotification}>
          {self?.user?.id ? (
            <CodeBlockRunButton
              onClick={doRun}
              disabled={running}
              loading={running}
            >
              {running ? 'Running...' : 'Run Now'}
            </CodeBlockRunButton>
          ) : (
            <LoginToRun>
              <a href={loginLink(window.location.href)}>log in</a> to run this
              example
            </LoginToRun>
          )}
        </CodeBlockElement.Section>
      )}

      {error && (
        <CodeBlockElement.Section className='p-0'>
          <BottomErrors
            title='Run has 1 error'
            // FIXME: strictNullCheck temporary fix
            // @ts-expect-error TS(2339): Property 'data' does not exist on type 'never'.
            errors={[error.data ?? error]}
            reverse
            isInSidebar={false}
          />
        </CodeBlockElement.Section>
      )}

      {!error && output && showOutput && (
        <CodeBlockElement.Output title={'Output'} output={output} />
      )}
    </CodeBlockElement.Root>
  )
}

export function codeBlocksToCodeExamples(
  codeBlocks: CodeBlockType,
): CodeExampleType[] {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type '{ language: CodeHighlighterLanguages; title:... Remove this comment to see the full error message
  return (Object.keys(codeBlocks) as CodeBlockExtension[]).map((ext) => ({
    language: mapExtensionToLanguage(ext),
    title: mapExtensionToLanguageTitle(ext),
    code: codeBlocks[ext],
  }))
}

function mapExtensionToLanguageTitle(ext: CodeBlockExtension) {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  return getCodeBlockExtension(ext).title
}
function mapExtensionToLanguage(ext: CodeBlockExtension) {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  return getCodeBlockExtension(ext).language
}
function getCodeBlockExtension(ext: CodeBlockExtension) {
  return CodeBlockExtensions.get(ext) || CodeBlockExtensions.get('js')
}

export function renderVariables(
  code: string,
  variables: Record<string, any>,
  parametersSpec?: ParametersSpec,
) {
  let result = code

  const defaultValues = {}
  for (const [key, value] of Object.entries(parametersSpec ?? {})) {
    if (typeof value === 'object' && value?.default) {
      defaultValues[key] = value.default
    }
  }

  const values = {
    ...defaultValues,
    ...(variables ?? {}),
  }

  for (const key of Object.keys(parametersSpec ?? {})) {
    let value = values?.[key]
    const parameterSpec = parametersSpec?.[key]
    const type =
      typeof parameterSpec === 'object' ? parameterSpec?.type : parameterSpec
    const defaultValue =
      typeof parameterSpec === 'object' ? parameterSpec?.default : undefined

    // For objects and booleans, we remove the outer quotes
    // that we add to make placeholders valid JSON (like '{OBJECT}' or '{BOOLEAN}')
    // For other types, we keep the quotes.
    const isStringType =
      type &&
      ![
        CodeParamType.Object,
        CodeParamType.Boolean,
        CodeParamType.DataSchema,
      ].includes(type)

    // Some keys can have format `baseKey/subKey` to store different values for different objects
    // for example, `INPUT/<objectId1>` and `INPUT/<objectId2>`.
    const baseKey = key.split('/')[0]

    const regexp = isStringType
      ? new RegExp(`{${baseKey}}`, 'g')
      : new RegExp(`['"]?{${baseKey}}['"]?`, 'g')

    if (value === undefined) {
      value = defaultValue
    }

    let stringValue = value === undefined ? '' : JSON.stringify(value, null, 2)

    if (isStringType) {
      stringValue = stringValue.replace(/"/g, '')
    }

    if (stringValue) {
      result = result.replace(regexp, stringValue)
    }
  }

  return result
}

ExampleCodeBlock.ParamType = CodeParamType

export { CodeParamType }
