import NextLink from 'next/link'
import { AnchorHTMLAttributes, forwardRef, PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'

export type UniversalLinkProps = {
  isNextLink?: boolean
  to: string
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const UniversalLink = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<UniversalLinkProps>
>(({ children, isNextLink = false, to, ...props }, ref) => {
  if (isNextLink) {
    return (
      <NextLink ref={ref} href={to} as={to}>
        <a {...props}>{children}</a>
      </NextLink>
    )
  }

  return (
    <NavLink ref={ref} to={to} {...props}>
      {children}
    </NavLink>
  )
})
