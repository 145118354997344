import clsx from 'utils/clsx'
import { useIntegration } from '@integration-app/react'
import { IconButton } from '@integration-app/ui/Button'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'

export function CreateDataSourceHeader({
  title,
  integrationId,
  onBack,
}: {
  title?: string
  integrationId?: string
  onBack?(): void
}) {
  return (
    <h2 className={clsx('flex flex-row gap-1', onBack && '-ml-2')}>
      {onBack && (
        <IconButton tooltip={'Back to Flow type selection'} onClick={onBack}>
          <SvgIcon type={SvgIconType.ChevronLeft} />
        </IconButton>
      )}
      {title || <CreateDataSourceTitle integrationId={integrationId} />}
    </h2>
  )
}

function CreateDataSourceTitle({ integrationId }: { integrationId?: string }) {
  const { integration } = useIntegration(integrationId)
  if (!integrationId) {
    return <>Create Universal Data Source</>
  }

  if (!integration?.name) {
    return <>Create Data Source</>
  }

  return <>Create Data Source for {integration?.name}</>
}
