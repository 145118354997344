import {
  DataCollectionSpec,
  DataSchema,
  DataSource,
  FieldMapping,
  Flow,
  FlowNode,
} from '@integration-app/sdk'
import { UnifiedDataModel } from '@integration-app/sdk'

export enum ConfigurationErrorType {
  BrokenVariable = 'BrokenVariable',
  MissingFieldValue = 'MissingFieldValue',
  BrokenLinkToNode = 'BrokenLinkToNode',
}

export type NodeConfigurationErrorData = {
  type: ConfigurationErrorType
  message: string
  valueLocator?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

export type NodeConfigurationErrorsGetterArgs = {
  flow: Flow
  nodeKey: string
  node: FlowNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any

  fieldMapping?: FieldMapping

  dataSource?: DataSource

  defaultPath?: string
  dataCollectionSpec?: DataCollectionSpec

  udm?: string
  udmSpec?: UnifiedDataModel

  runTimeVariablesSchema: DataSchema
  inputSchema: DataSchema
}

export type NodeConfigurationErrorsGetter = (
  args: NodeConfigurationErrorsGetterArgs,
) => NodeConfigurationErrorData[]
