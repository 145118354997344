import { HTMLAttributes, useState } from 'react'
import Toggle from '@integration-app/ui/Toggle'
import clsx from 'utils/clsx'
import classes from './CodeModeSwitcher.module.css'

interface CodeModeSwitcherProps extends HTMLAttributes<HTMLDivElement> {
  codeMode: boolean
  onToggle()
}

export function CodeModeSwitcher({
  codeMode,
  onToggle,
  className,
  ...props
}: CodeModeSwitcherProps) {
  return (
    <div
      className={clsx(
        className,
        classes.toggleContainer,
        !codeMode && classes.toggleContainer__left,
        codeMode && classes.toggleContainer__right,
      )}
      onClick={onToggle}
      role={'button'}
      {...props}
    >
      <span className={classes.leftTitle}>UI</span>
      <Toggle checked={codeMode} />
      <span className={classes.rightTitle}>Code</span>
    </div>
  )
}

export function useCodeMode() {
  const [codeMode, setCodeMode] = useState(false)

  function toggleCodeMode() {
    setCodeMode(!codeMode)
  }

  return {
    codeMode,
    toggleCodeMode,
  }
}
