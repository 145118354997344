import QueryString from 'qs'

export interface IHasId {
  id: string
}

export interface IHasArchivedAt {
  archivedAt?: string | Date
}

export interface IHasKeyAndName {
  key: string
  name?: string
}

export interface IInstanceBasicFields extends IHasId, IHasArchivedAt {}

export interface IIntegrationElementBasicFields
  extends IHasId,
    IHasKeyAndName,
    IHasArchivedAt {}

export interface IRouteParams {
  route: string
  query?: Record<string, unknown>
}

export function buildRouteUrl(params: IRouteParams) {
  const { route, query } = params
  if (!route) return null

  return `${route}?${QueryString.stringify(query, {
    skipNulls: true,
  })}`
}
