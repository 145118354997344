/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Integration.app Documentation",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Use Cases",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Products",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Integration Engine",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Connector Builder",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Integration UX",
      "attributes": {},
      "children": []
    }]
  }]
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components), {DocGrid, DocLink, DocPanel} = _components;
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!DocPanel) _missingMdxReference("DocPanel", true);
  if (!DocPanel.Container) _missingMdxReference("DocPanel.Container", true);
  if (!DocPanel.Link) _missingMdxReference("DocPanel.Link", true);
  if (!DocPanel.Links) _missingMdxReference("DocPanel.Links", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "integrationapp-documentation",
      children: "Integration.app Documentation"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx("a", {
        href: "https://integration.app",
        children: "Integration.app"
      }), " provides integration infrastructure and ecosystem for SaaS products."]
    }), "\n", _jsx(_components.p, {
      children: "It helps you:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Connect your app to any other software your customers use."
      }), "\n", _jsx(_components.li, {
        children: "Build, run, and troubleshoot integration logic between your app and external apps."
      }), "\n", _jsx(_components.li, {
        children: "Create a seamless UI/UX for your customers to enable and configure integrations."
      }), "\n"]
    }), "\n", _jsx("br", {}), "\n", _jsxs(DocGrid, {
      cols: 2,
      children: [_jsx(DocGrid.Link, {
        highlight: true,
        path: "overview/getting-started"
      }), _jsx(DocGrid.Link, {
        path: "overview/building-integrations",
        title: "Building your next integration"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "use-cases",
      children: "Use Cases"
    }), "\n", _jsx(_components.p, {
      children: "You can build anything from simple, one-click integrations to complex, fully custom workflow builders using our infrastructure.\nThis section will guide you through the possibilities and help you learn how to build what you need."
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "solutions/data-import/continuous-import"
      }), _jsx(DocGrid.Link, {
        path: "solutions/data-push"
      }), _jsx(DocGrid.Link, {
        path: "solutions/bi-directional-sync"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "products",
      children: "Products"
    }), "\n", _jsx(_components.h3, {
      id: "integration-engine",
      children: "Integration Engine"
    }), "\n", _jsx(_components.p, {
      children: "Integration Engine is a core of your integration infrastructure. It is a headless, highly configurable API-first service that manages, runs, and monitors your integrations. You can run it in our cloud or on your own infrastructure."
    }), "\n", _jsxs(_components.p, {
      children: ["To learn more, see ", _jsx(DocLink, {
        path: "engine"
      }), "."]
    }), "\n", _jsx(_components.h3, {
      id: "connector-builder",
      children: "Connector Builder"
    }), "\n", _jsx(_components.p, {
      children: "Connector Builder is an AI-powered tool for quickly converting API documentations and specifications into production-ready connectors that you can use with Integration Engine."
    }), "\n", _jsxs(_components.p, {
      children: ["Read more: ", _jsx(DocLink, {
        path: "connector-builder"
      }), "."]
    }), "\n", _jsx(_components.h3, {
      id: "integration-ux",
      children: "Integration UX"
    }), "\n", _jsx(_components.p, {
      children: "You can build a wide variety of integration experiences on top of our engine using pre-built components or fully custom UI."
    }), "\n", _jsxs(DocPanel.Container, {
      children: [_jsxs(DocPanel, {
        src: "/images/docs/ui-integration-list.png",
        children: [_jsx("strong", {
          children: "List of Integrations"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/integration-list',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/integration-list/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-data-source.png",
        children: [_jsx("strong", {
          children: "Data Source Configuration"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/data-sources',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/data-sources/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-field-mapping.png",
        children: [_jsx("strong", {
          children: "Field Mapping Configuration"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/field-mappings',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/field-mappings/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-connection.png",
        children: [_jsx("strong", {
          children: "Connection UI"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/connection',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/connection/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-single-integration.png",
        children: [_jsx("strong", {
          children: "Single Integration"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/integration',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/integration/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-flow.png",
        children: [_jsx("strong", {
          children: "Flow Configuration"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/flows',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/flows/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
