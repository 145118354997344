import { schemaWithTitle } from '@integration-app/sdk'
import { makeDataField, SimpleInput } from '@integration-app/ui'
import Button from '@integration-app/ui/Button'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import clsx from 'utils/clsx'
import { CodeBlockElement } from 'components/CodeBlock/elements'
import {
  CodeParamType,
  ParameterSpec,
  ParametersSpec,
} from 'components/Docs/ExampleCodeBlock'
import { ActionComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/ActionComboboxSelect'
import { BooleanComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/BooleanComboboxSelect'
import { ConnectionComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/ConnectionComboboxSelect'
import { DataSourceKeyComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/DataSourceKeyComboboxSelect'
import { FieldMappingComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/FieldMappingComboboxSelect'
import { FlowComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/FlowComboboxSelect'
import { IntegrationComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationComboboxSelect'
import { IntegrationWithConnectionComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationWithConnectionComboboxSelect'
import { UdmComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/UdmComboboxSelect'
import { toHeaderCase } from 'js-convert-case'
import { useState } from 'react'
import useDocs from 'routes/Docs/components/docs-context'
import { FlowInstanceComboboxSelect } from '../../IntegrationElements/ComboboxSelects/FlowInstanceComboboxSelect'

export function CodeParams({
  disabled,
  expandedByDefault = false,
  parametersSpec,
}: {
  disabled?: boolean
  expandedByDefault?: boolean
  parametersSpec: ParametersSpec
}) {
  const { isVariableParameter } = useDocs()
  const [showParameters, setShowParameters] = useState(expandedByDefault)

  const editableParameters = Object.keys(parametersSpec ?? {})
    .filter((key) => isVariableParameter(key))
    .filter((key) => !(parametersSpec?.[key] as ParameterSpec)?.readOnly)

  const amountOfParameters = editableParameters.length

  const toggleParameters = () => {
    setShowParameters(!showParameters)
  }

  if (!amountOfParameters) {
    return null
  }

  if (disabled) {
    return null
  }

  return (
    <CodeBlockElement.Section className={'pt-0'}>
      {amountOfParameters > 1 && !expandedByDefault && (
        <Button
          variant={'secondary'}
          size={'small'}
          onClick={toggleParameters}
          className={clsx(
            'w-44 justify-center rounded-t-none -mt-[1px]',
            showParameters && 'mb-2.5',
          )}
        >
          {showParameters ? 'Hide Parameters' : 'Edit Parameters'}

          <SvgIcon
            type={SvgIconType.Settings}
            className={'ml-2 -mr-2 w-5 h-5'}
          ></SvgIcon>
        </Button>
      )}
      {(amountOfParameters <= 1 || showParameters) && (
        <ParamsList parametersSpec={parametersSpec} />
      )}
    </CodeBlockElement.Section>
  )
}

function ParamsList({ parametersSpec }: { parametersSpec: ParametersSpec }) {
  const { parameters, patchParameters, isVariableParameter } = useDocs()

  const parametersToRender: [string, CodeParamType | ParameterSpec][] =
    Object.entries(parametersSpec ?? {}).filter(([name]) =>
      isVariableParameter(name),
    )

  return (
    <div className={'grid grid-cols-1 gap-2 w-full'}>
      {parametersToRender.map(([name, parameterSpec]) =>
        isVariableParameter(name) ? (
          <RunParam
            key={name}
            name={name}
            spec={parameterSpec as ParameterSpec}
            type={
              typeof parameterSpec === 'object'
                ? parameterSpec?.type
                : parameterSpec
            }
            value={parameters?.[name]}
            onChange={(value) => patchParameters({ [name]: value })}
          />
        ) : null,
      )}
    </div>
  )
}

function RunParam({
  name,
  type,
  value: providedValue,
  spec,
  onChange,
}: {
  name: string
  type: CodeParamType
  value: any
  spec: ParameterSpec
  onChange: (value: any) => void
}) {
  let value = providedValue
  if (value === undefined) {
    value = spec?.default
  }

  switch (type) {
    case CodeParamType.String:
      return (
        <DataBuilderForm
          field={makeDataField({
            schema: schemaWithTitle(
              {
                type: 'string',
              },
              toHeaderCase(name),
            ),
            value,
          })}
          onChange={onChange}
        />
      )
    case CodeParamType.Boolean:
      return (
        <BooleanComboboxSelect name={name} value={value} onChange={onChange} />
      )
    case CodeParamType.integrationKeyWithoutConnection:
      return <IntegrationComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.IntegrationKey:
      return (
        <IntegrationWithConnectionComboboxSelect
          integrationKey={value}
          onIntegrationSelect={onChange}
        />
      )
    case CodeParamType.ConnectionId:
      return <ConnectionComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.FieldMappingKey:
      return <FieldMappingComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.FlowKey:
      return <FlowComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.FlowInstanceId:
      return <FlowInstanceComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.ActionKey:
      return <ActionComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.DataSourceKey:
      return <DataSourceKeyComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.UDM:
      return <UdmComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.Object:
      // Objects can only be set from code, not by user (for now)
      return null
    case CodeParamType.DataSchema:
      return spec?.schema ? (
        <div>
          <DataBuilderForm
            field={makeDataField({
              schema: schemaWithTitle(
                spec?.schema,
                spec?.title ?? toHeaderCase(name),
              ),
              value,
            })}
            onChange={onChange}
          />
        </div>
      ) : null
    default:
      return <SimpleInput label={name} value={value} onChange={onChange} />
  }
}
