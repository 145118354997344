import {
  DataSchema,
  IntegrationElementLevel,
  pickFieldsFromSchema,
  removeRequiredFieldsFromSchema,
  schemaWithTitle,
} from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { CollapsibleSection } from '../Section'
import { useGenericConfig } from './contexts/generic-config-context'

export function ListFilterConfig() {
  const {
    level,
    dataCollectionSpec,
    udmSpec,
    patchConfig,
    config,
    variablesSchema,
  } = useGenericConfig()

  let filterFieldsSchema: DataSchema | undefined
  let value
  let onChange
  if (level === IntegrationElementLevel.UNIVERSAL) {
    const fieldsSchema = udmSpec?.fieldsSchema
    filterFieldsSchema = schemaWithTitle(
      removeRequiredFieldsFromSchema(
        pickFieldsFromSchema(fieldsSchema, udmSpec?.list?.filterFields),
      ),
      'Filter by Fields',
    )
    value = config.unifiedFilter
    onChange = (unifiedFilter) => patchConfig({ unifiedFilter })
  } else {
    const fieldsSchema = dataCollectionSpec?.fieldsSchema
    filterFieldsSchema = schemaWithTitle(
      removeRequiredFieldsFromSchema(
        pickFieldsFromSchema(
          fieldsSchema,
          dataCollectionSpec?.list?.filterFields,
        ),
      ),
      'Filter by Fields',
    )
    value = config.filter
    onChange = (filter) => patchConfig({ filter })

    filterFieldsSchema = removeRequiredFieldsFromSchema(
      schemaWithTitle(filterFieldsSchema, 'Filter by Fields'),
    )
  }

  const hasFilterFields =
    Object.keys(filterFieldsSchema?.properties || {}).length > 0

  if (!hasFilterFields) {
    return null
  }

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Filter</CollapsibleSection.Header>

      <DataBuilderForm
        onChange={onChange}
        field={makeDataField({
          variablesSchema,
          value,
          schema: filterFieldsSchema,
        })}
      />
    </CollapsibleSection>
  )
}
