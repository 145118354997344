import { CreateFlowRequest, Flow } from '@integration-app/sdk'
import Button from '@integration-app/ui/Button'
import { CodeBlockElement } from 'components/CodeBlock/elements'
import { LoginToRun } from 'components/CodeBlock/components'
import useWorkspace from 'components/Workspaces/workspace-context'
import useAuth from 'contexts/auth'
import deepEqual from 'fast-deep-equal'
import Link from 'next/link'
import { useState } from 'react'
import useSWR from 'swr'
import TableCellTag from '../TableCellTag'
import classes from './ExampleFlowBlock.module.css'
import { FlowPreview } from './FlowPreview'

export default function ExampleFlowBlock({
  flow: flowData,
}: {
  flow: CreateFlowRequest
}) {
  const { self, loginLink } = useAuth()
  const { engineAdminClient, engineAdminFetcher } = useWorkspace()

  const [resettingFlow, setResettingFlow] = useState(false)
  const [creatingFlow, setCreatingFlow] = useState(false)

  const flowPath = `/flows/${flowData.key}`

  const {
    data: flow,
    mutate: mutateFlow,
    error,
  } = useSWR<Flow>(flowPath, engineAdminFetcher)

  const flowExists = !!flow && !error // TODO: check if flow archived

  const flowWasModified = flowExists && !isFlowEqual(flow, flowData)

  async function createFlow() {
    setCreatingFlow(true)
    try {
      if (flowExists) {
        await engineAdminClient.flow(flow.key).archive()
      }

      await engineAdminClient.flows.create(flowData)
      await mutateFlow()

      // sleep for better ux
      await new Promise((resolve) => setTimeout(resolve, 1000))
    } finally {
      setCreatingFlow(false)
    }
  }

  async function resetFlow() {
    setResettingFlow(true)
    try {
      await engineAdminClient.flow(flowData.key).put(flowData)
      await mutateFlow()

      // sleep for better ux
      await new Promise((resolve) => setTimeout(resolve, 1000))
    } finally {
      setResettingFlow(false)
    }
  }

  if (!self?.user) {
    return (
      <CodeBlockElement.Root className={'mt-4 mb-6'}>
        <ExampleBlockSectionFlowPreview flowNodes={flowData.nodes} />

        <CodeBlockElement.Section>
          <LoginToRun>
            Please <a href={loginLink(window.location.href)}>log in</a> to try
            out this flow.
          </LoginToRun>
        </CodeBlockElement.Section>
      </CodeBlockElement.Root>
    )
  }

  return (
    <CodeBlockElement.Root className={'mt-4 mb-6'}>
      {flowExists ? (
        <ExampleBlockSectionFlowPreview flowNodes={flow.nodes} />
      ) : (
        <ExampleBlockSectionFlowPreview flowNodes={flowData.nodes} />
      )}

      <CodeBlockElement.Section className='flex flex-row justify-end'>
        <Button
          disabled={creatingFlow || resettingFlow}
          loading={creatingFlow && !resettingFlow}
          onClick={createFlow}
        >
          {flowExists ? 'Recreate Flow' : 'Create Flow'}
        </Button>
      </CodeBlockElement.Section>

      {flowExists && !creatingFlow && (
        <CodeBlockElement.Section className='flex flex-row items-center gap-2'>
          <TableCellTag copyToClipboard={false}>
            <span>See in Flow Builder: </span>
            {/* TODO */}
            <Link href='flows/[flowId]' as={`flows/${flow.id}`}>
              <a>{flow.name}</a>
            </Link>
          </TableCellTag>

          {flowWasModified && (
            <>
              <div className='flex-grow'></div>
              <div className='text-error'>Flow was modified</div>
              <Button
                disabled={resettingFlow}
                loading={resettingFlow}
                variant='secondary'
                onClick={resetFlow}
              >
                Reset Flow to the Initial State
              </Button>
            </>
          )}
        </CodeBlockElement.Section>
      )}
    </CodeBlockElement.Root>
  )
}

function ExampleBlockSectionFlowPreview({ flowNodes }) {
  return (
    <CodeBlockElement.Section
      className={classes.flowPreviewExampleSectionBlock}
    >
      <div className={classes.flowPreviewGraphContainer}>
        <FlowPreview flowNodes={flowNodes as Flow['nodes']} />
      </div>
    </CodeBlockElement.Section>
  )
}

function isFlowEqual(flow: Flow, original: CreateFlowRequest) {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2769): No overload matches this call.
  const flowKeys = Object.keys(flow.nodes)
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2769): No overload matches this call.
  const originalKeys = Object.keys(original.nodes)
  if (flowKeys.length !== originalKeys.length) {
    return false
  }
  if (flowKeys.some((key) => !originalKeys.includes(key))) {
    return false
  }
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2769): No overload matches this call.
  for (const key of Object.keys(flow.nodes)) {
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const flowNode = flow.nodes[key]
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const originalNode = original.nodes[key]
    if (!deepEqual(flowNode.config, originalNode.config)) {
      return false
    }
  }
  return true
}
