/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Getting Started",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "1. Add integrations",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "2. Create a test connection",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "3. Make a test request",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "4. Build integration logic",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "5. Add integrations to your app",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "5.1. Set up a front-end SDK",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "5.2. Create a Connection",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "5.3. Deploy integrations",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "5.4. Run integrations",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "6. Build integration UX (optional)",
    "attributes": {},
    "children": []
  }]
}];
import {DocGrid, DocPanel} from 'components/Docs/DocLinksGrid';
import SubHeader from 'components/Docs/SubHeader';
import {CONNECTIONS_ROUTE, INTEGRATIONS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const DESCRIPTION = 'Set up integration.app and implement your first integration';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    img: "img",
    h3: "h3",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {DocLink, Link} = _components;
  if (!DocGrid) _missingMdxReference("DocGrid", false);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!DocPanel) _missingMdxReference("DocPanel", false);
  if (!DocPanel.Container) _missingMdxReference("DocPanel.Container", true);
  if (!DocPanel.Link) _missingMdxReference("DocPanel.Link", true);
  if (!DocPanel.Links) _missingMdxReference("DocPanel.Links", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "getting-started",
      children: "Getting Started"
    }), "\n", _jsx(_components.p, {
      children: "Here is how to start using integration.app and implement your first integration."
    }), "\n", _jsx(_components.h2, {
      id: "1-add-integrations",
      children: "1. Add integrations"
    }), "\n", _jsxs(_components.p, {
      children: ["To start, select one or more ", _jsx(DocLink, {
        path: "engine/external-apps",
        children: "external apps"
      }), " you want to integrate with. You can do it from the ", _jsx(Link, {
        path: INTEGRATIONS_ROUTE,
        children: "Apps"
      }), " page."]
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/add-integrations.png",
      alt: "Adding integrations"
    }), "\n", _jsx(_components.p, {
      children: "If you can't find the app you need, you can still add an integration with it by typing the app's name in the search bar.\nAs long as the app has an API, you will be able to integrate with it."
    }), "\n", _jsx(_components.h2, {
      id: "2-create-a-test-connection",
      children: "2. Create a test connection"
    }), "\n", _jsxs(_components.p, {
      children: ["To test your integration, create a test connection from the corresponding ", _jsx(Link, {
        path: INTEGRATIONS_ROUTE,
        children: "integration page"
      }), "."]
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/create-test-connection.png",
      alt: "Adding integrations"
    }), "\n", _jsx(_components.p, {
      children: "You will need a test account for this step.\nYou can use integration for an app you already have an account with or use documentation section of the integration to create a new one."
    }), "\n", _jsx(_components.h2, {
      id: "3-make-a-test-request",
      children: "3. Make a test request"
    }), "\n", _jsx(_components.p, {
      children: "Creating a connection allows you to communicate with the other app's API on behalf of your test account. Try it out by making a test request to the external app's API."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/make-test-api-request.png",
      alt: "Adding integrations"
    }), "\n", _jsx(_components.p, {
      children: "You will rarely have to make low-level API requests to external apps, but you will always have this option if you need it."
    }), "\n", _jsx(_components.h2, {
      id: "4-build-integration-logic",
      children: "4. Build integration logic"
    }), "\n", _jsx(_components.p, {
      children: "There are dozens of different ways to build an integration - depending on complexity, usage scenarios, and your preferences.\nTo navigate these options, start with this article:"
    }), "\n", _jsx(DocGrid, {
      children: _jsx(DocGrid.Link, {
        path: "overview/building-integrations"
      })
    }), "\n", _jsx(_components.h2, {
      id: "5-add-integrations-to-your-app",
      children: "5. Add integrations to your app"
    }), "\n", _jsx(_components.p, {
      children: "After you built and tested an integration, you need to add it to your app. Here is how you can do it:"
    }), "\n", _jsx(_components.h3, {
      id: "51-set-up-a-front-end-sdk",
      children: "5.1. Set up a front-end SDK"
    }), "\n", _jsx(_components.p, {
      children: "To connect your customer accounts and use integrations from your front-end, you need to install an SDK. You can choose from one of these options:"
    }), "\n", _jsxs(DocGrid, {
      cols: 2,
      children: [_jsx(DocGrid.Link, {
        path: "overview/getting-started/front-end/javascript"
      }), _jsx(DocGrid.Link, {
        path: "overview/getting-started/front-end/react"
      })]
    }), "\n", _jsx(_components.h3, {
      id: "52-create-a-connection",
      children: "5.2. Create a Connection"
    }), "\n", _jsxs(_components.p, {
      children: ["Before running integrations, you need to connect your customer's account in an external application. It creates a ", _jsx(_components.strong, {
        children: "Connection"
      }), " object that stores and refreshes the customer's credentials."]
    }), "\n", _jsx(_components.p, {
      children: "You can use one of our pre-buitl UI components (recommended for quick start) or build a fully custom UI."
    }), "\n", _jsxs(DocGrid, {
      cols: 2,
      children: [_jsx(DocGrid.Link, {
        highlight: true,
        path: "ux/integration-list"
      }), _jsx(DocGrid.Link, {
        path: "ux/integration-list/custom"
      }), _jsx(DocGrid.Link, {
        path: "ux/connection"
      }), _jsx(DocGrid.Link, {
        path: "ux/connection/custom"
      })]
    }), "\n", _jsxs(_components.p, {
      children: ["You can see the list of all the customer connections on the ", _jsx(Link, {
        path: CONNECTIONS_ROUTE,
        children: "Connections"
      }), " page."]
    }), "\n", _jsx(_components.h3, {
      id: "53-deploy-integrations",
      children: "5.3. Deploy integrations"
    }), "\n", _jsxs(_components.p, {
      children: ["Depending on the scenario you've created, you need to deploy ", _jsx(DocLink, {
        path: "engine/deployments/action-instances"
      }), " or ", _jsx(DocLink, {
        path: "engine/deployments/flow-instances"
      }), " for the customer connection you've created before running them."]
    }), "\n", _jsx(_components.p, {
      children: "You can find instructions for the specific Action or Flow following \"How to deploy\" link."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/how-to-deploy.png",
      alt: "How to Deploy Link"
    }), "\n", _jsx(_components.h3, {
      id: "54-run-integrations",
      children: "5.4. Run integrations"
    }), "\n", _jsx(_components.p, {
      children: "Depending on the integration blueprint you've built, your integrations will either run automatically after deployment when a trigger event occurs, or you will need to run them through API."
    }), "\n", _jsx(_components.p, {
      children: "You can find the instructions on the page of an Action / Flow you want to run. For reference, here are the relevant APIs for triggering integrations:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/deployments/action-instances"
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/deployments/flow-instances"
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/deployments/app-event-subscriptions"
        }), "\n"]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "6-build-integration-ux-optional",
      children: "6. Build integration UX (optional)"
    }), "\n", _jsx(_components.p, {
      children: "Each of your customers has different integration setup. Integration UI is a key part of your product's experience.\nEach integration you build on our platform comes with full customization capabilities."
    }), "\n", _jsx(_components.p, {
      children: "You can use our drop-in UI components or build fully custom white-label UI."
    }), "\n", _jsxs(DocPanel.Container, {
      children: [_jsxs(DocPanel, {
        src: "/images/docs/ui-integration-list.png",
        children: [_jsx("strong", {
          children: "List of Integrations"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/integration-list',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/integration-list/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-data-source.png",
        children: [_jsx("strong", {
          children: "Data Source Configuration"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/data-sources',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/data-sources/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-field-mapping.png",
        children: [_jsx("strong", {
          children: "Field Mapping Configuration"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/field-mappings',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/field-mappings/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-connection.png",
        children: [_jsx("strong", {
          children: "Connection UI"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/connection',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/connection/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-single-integration.png",
        children: [_jsx("strong", {
          children: "Single Integration"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/integration',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/integration/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-flow.png",
        children: [_jsx("strong", {
          children: "Flow Configuration"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/flows',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/flows/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
