import { CollapsibleSection } from '../../Section'
import { useGenericConfig } from '../contexts/generic-config-context'
import SidePanel from 'components/Layout/SidePanel'
import UdmSelect from '../../../../../../components/DataSources/UdmSelect'
import { SharedDataSource } from './SharedDataSource'
import {
  DataCollectionSelect,
  DataSourceConfigureEvents,
  SimpleRadio,
} from '@integration-app/ui'

enum DataSourceUnitConfigOption {
  Model = 'model',
  Shared = 'shared',
  Input = 'input',
}

export function UniversalDataSourceUnitConfig({
  configureEvents,
}: {
  configureEvents: boolean
}) {
  const {
    patchDataSourceConfig,
    dataSourceConfig,
    patchConfig,
    variablesSchema,
  } = useGenericConfig()

  async function handleSelectOption(option: DataSourceUnitConfigOption) {
    switch (option) {
      case DataSourceUnitConfigOption.Model:
        return patchConfig({
          dataSource: {
            udm: null,
          },
        })
      case DataSourceUnitConfigOption.Shared:
        return patchConfig({
          dataSource: {
            key: null,
          },
        })
      case DataSourceUnitConfigOption.Input:
        return patchConfig({
          dataSource: {
            collectionKey: null,
          },
        })
    }
  }

  let selectedOption = DataSourceUnitConfigOption.Model

  if (dataSourceConfig?.udm !== undefined) {
    selectedOption = DataSourceUnitConfigOption.Model
  }

  if (dataSourceConfig?.collectionKey !== undefined) {
    selectedOption = DataSourceUnitConfigOption.Input
  }

  if (dataSourceConfig?.key !== undefined) {
    selectedOption = DataSourceUnitConfigOption.Shared
  }

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Data Source</CollapsibleSection.Header>
      <p>
        Select which data in the external application do you want to use in this
        integration.
      </p>

      <SimpleRadio
        label='Pick best match for each application'
        checked={selectedOption === DataSourceUnitConfigOption.Model}
        onCheck={() => handleSelectOption(DataSourceUnitConfigOption.Model)}
      />
      <SimpleRadio
        label='Use shared data source configuration'
        checked={selectedOption === DataSourceUnitConfigOption.Shared}
        onCheck={() => handleSelectOption(DataSourceUnitConfigOption.Shared)}
      />
      <SimpleRadio
        label='Use input variables'
        checked={selectedOption === DataSourceUnitConfigOption.Input}
        onCheck={() => handleSelectOption(DataSourceUnitConfigOption.Input)}
      />

      <SidePanel.Content>
        {selectedOption === DataSourceUnitConfigOption.Model && (
          <>
            <p>
              Integration engine will automatically pick the best matching
              collection for each application based on the selected data model.
            </p>
            <UdmSelect
              value={dataSourceConfig.udm}
              onChange={(udm) => patchDataSourceConfig({ udm })}
            />
          </>
        )}
        {selectedOption === DataSourceUnitConfigOption.Shared && (
          <>
            <p>
              This data source configuration will be shared between every place
              that uses it.
            </p>
            <SharedDataSource />
          </>
        )}
        {selectedOption === DataSourceUnitConfigOption.Input && (
          <>
            <p>Select data collection dynamically using variables.</p>
            <DataCollectionSelect
              value={dataSourceConfig}
              onChange={(dataSource) =>
                patchConfig({
                  dataSource: {
                    ...(dataSource ?? {}),
                    collectionKey: dataSource?.collectionKey ?? null, // Always provide collectionKey to not reset selected option when un-selecting collection
                  },
                })
              }
              variablesSchema={variablesSchema}
            />
            {configureEvents && (
              <DataSourceConfigureEvents
                value={dataSourceConfig}
                onChange={(dataSource) => patchConfig({ dataSource })}
              />
            )}
          </>
        )}
      </SidePanel.Content>
    </CollapsibleSection>
  )
}
