import { DataSchema } from '@integration-app/sdk'
import { SchemaBuilder } from '@integration-app/ui'
import { makeDataField } from '@integration-app/ui/DataBuilder'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import useSWR from 'swr'
import useWorkspace from '../../../../../components/Workspaces/workspace-context'
import { getBrokenVariablesConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/getBrokenVariablesConfigurationErrors'
import {
  ConfigurationErrorType,
  NodeConfigurationErrorData,
} from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'
import { ResetCallOut } from '../../Blueprints/components/ResetCallOut'
import { CollapsibleSection } from '../Section'
import { useGenericConfig } from './contexts/generic-config-context'
import useApi from '../../../../../hooks/useApi'

const DEFAULT_REQUEST_SCHEMA = {
  type: 'object',
  properties: {
    headers: {
      additionalProperties: true,
      properties: {},
      type: 'object',
    },
    query: {
      additionalProperties: true,
      properties: {},
      type: 'object',
    },
    data: {
      additionalProperties: true,
      properties: {},
      type: 'object',
    },
  },
}

export function ApiRequestToExternalAppConfig() {
  const { engineAdminFetcher } = useWorkspace()
  const { apiFetcher } = useApi()
  const { config, patchConfig, variablesSchema, integrationId, integration } =
    useGenericConfig()

  const { path, method, ...request } = config?.request ?? {}

  const openapiUri = `/integrations/${integrationId}/openapi`

  const { data: connector } = useSWR(
    integration?.connectorId
      ? `/connectors/${integration.connectorId}`
      : undefined,
    apiFetcher,
  )
  const { data: paths } = useSWR(
    integrationId ? `${openapiUri}/paths` : undefined,
    engineAdminFetcher,
  )
  const { data: methods } = useSWR(
    integrationId && path
      ? `${openapiUri}/path-methods?path=${path}`
      : undefined,
    engineAdminFetcher,
  )
  const { data: requestSchema } = useSWR<DataSchema>(
    integrationId && path && method
      ? `${openapiUri}/request-schema?path=${path}&method=${method}`
      : undefined,
    engineAdminFetcher,
  )

  const { data: responseSchema } = useSWR<DataSchema>(
    integrationId && path && method
      ? `${openapiUri}/response-schema?path=${path}&method=${method}`
      : undefined,
    engineAdminFetcher,
  )

  async function patchRequestConfig(data: any) {
    return patchConfig({
      request: {
        ...(config.request ?? {}),
        ...data,
      },
    })
  }

  return (
    <>
      <CollapsibleSection open>
        <CollapsibleSection.Header>Request</CollapsibleSection.Header>
        {connector?.apiDocsUri && (
          <div className='my-2'>
            See{' '}
            <a className='link' href={connector.apiDocsUri} target='_blank'>
              {integration?.name} API Documentation
            </a>{' '}
            for reference.
          </div>
        )}
        <DataBuilderForm
          field={makeDataField({
            schema: {
              title: 'Path',
              type: 'string',
              enum: paths,
              allowCustom: true,
            },
            variablesSchema,
            value: path,
          })}
          onChange={(path) => patchRequestConfig({ path })}
        />
        <DataBuilderForm
          field={makeDataField({
            schema: {
              title: 'Method',
              type: 'string',
              enum: methods,
              allowCustom: true,
            },
            variablesSchema,
            value: method,
          })}
          frozenFieldsLocators={path ? [] : ['$']}
          onChange={(method) => patchRequestConfig({ method })}
        />
        {path && method && (
          <div className='my-2'>
            <DataBuilderForm
              field={makeDataField({
                schema: requestSchema || DEFAULT_REQUEST_SCHEMA,
                variablesSchema,
                value: request,
              })}
              onChange={(request) =>
                patchRequestConfig({
                  query: request?.query,
                  data: request?.data,
                  headers: request?.headers,
                  pathParameters: request?.pathParameters,
                })
              }
            />
          </div>
        )}
      </CollapsibleSection>
      <CollapsibleSection>
        <CollapsibleSection.Header>Response Schema</CollapsibleSection.Header>
        <p>What response to expect from this request</p>

        {config?.responseSchema && responseSchema && (
          <ResetCallOut
            reset={() => patchConfig({ responseSchema: undefined })}
            description='This schema was modified from the API specification.'
          />
        )}

        <SchemaBuilder
          schema={config.responseSchema ?? responseSchema}
          isObject={false}
          onChange={(responseSchema) => patchConfig({ responseSchema })}
        />
      </CollapsibleSection>
    </>
  )
}

export function getCustomApiRequestConfigErrors({
  config,
  runTimeVariablesSchema,
}) {
  const errors: NodeConfigurationErrorData[] = []

  const path = config.request?.path
  if (!path) {
    errors.push({
      type: ConfigurationErrorType.MissingFieldValue,
      message: 'Request path is required.',
      valueLocator: '$.path',
    })
  }

  errors.push(
    ...getBrokenVariablesConfigurationErrors(config?.request, [
      runTimeVariablesSchema,
    ]),
  )

  return errors
}
