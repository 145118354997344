import ComboBox from '@integration-app/ui/ComboBox'
import { ValueType } from '@integration-app/ui/ComboBox/value-spec'
import { ComboBoxTag } from '@integration-app/ui/ComboBoxElements/ComboBoxTag'
import classes from './ComboboxSelects.module.css'
import TagPlaceholder from '@integration-app/ui/Tag/TagPlaceholder'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { ReactNode } from 'react'
import clsx from 'utils/clsx'
import { ComboBoxOptionsFactoryInterface } from '@integration-app/ui/ComboBox/options-factories'
import { ComboBoxOptionType } from '@integration-app/ui/ComboBox/types'
import Tag from '@integration-app/ui/Tag'

export interface ItemComboboxSelectProps<Item> {
  name?: string
  value: Item
  onChange: (value: Item) => void

  editable?: boolean
  deletable?: boolean

  /*
   * This prop is used control right side border styles.
   */
  hasRightSibling?: boolean
}

interface CommonItemComboboxSelectProps<Item>
  extends ItemComboboxSelectProps<Item> {
  valueComponent: ReactNode
  valuePlaceholderComponent: ReactNode

  options?: ComboBoxOptionType[]
  optionFactories?: ComboBoxOptionsFactoryInterface[]
}

const COMMON_ITEM_VALUE_SPEC_SCHEMA = {
  type: ValueType.CUSTOM,
  allowCustom: false,
}

export function CommonItemComboboxSelect<Item>({
  value,
  onChange,
  optionFactories,
  options,
  editable = true,
  deletable = true,
  hasRightSibling = false,

  valueComponent,
  valuePlaceholderComponent,
}: CommonItemComboboxSelectProps<Item>) {
  if (!editable) {
    return (
      <ItemComboBoxTagValue<Item>
        hasRightSibling={hasRightSibling}
        value={value}
        valueComponent={valueComponent}
        valuePlaceholderComponent={valuePlaceholderComponent}
      />
    )
  }

  return (
    <ComboBox
      value={value}
      valueSpec={COMMON_ITEM_VALUE_SPEC_SCHEMA}
      onChange={onChange}
      options={options}
      optionFactories={optionFactories}
      valueComponent={
        <ItemComboBoxTagValue<Item>
          hasRightSibling={hasRightSibling}
          deletable={deletable}
          value={value}
          valueComponent={valueComponent}
          valuePlaceholderComponent={valuePlaceholderComponent}
        />
      }
    />
  )
}

export function ItemComboBoxTagValue<Item>({
  value,
  hasRightSibling,
  valueComponent,
  valuePlaceholderComponent,
  deletable = true,
}: {
  value: Item
  hasRightSibling?: boolean
  valueComponent: ReactNode
  valuePlaceholderComponent: ReactNode
  deletable?: boolean
}) {
  if (value !== undefined && value !== null) {
    return (
      <ComboBoxTag
        className={clsx(
          classes.elementComboboxTag,
          hasRightSibling && classes.withRightSibling,
          !deletable && classes.withoutDeleteButton,
        )}
      >
        {valueComponent}
      </ComboBoxTag>
    )
  }

  return (
    <TagPlaceholder
      className={clsx(
        classes.elementComboboxTagPlaceholder,
        hasRightSibling && classes.withRightSibling,
        !deletable && classes.withoutDeleteButton,
      )}
    >
      {valuePlaceholderComponent}
    </TagPlaceholder>
  )
}

export function ItemComboBoxGenericValue({
  title,
  value,
  imageSrc,
  imageAlt,
}: {
  title: string
  value: string
  imageSrc?: string
  imageAlt?: string
}) {
  return (
    <>
      {title}
      <ElementName name={value} />
      {imageSrc && <Tag.Image src={imageSrc} alt={imageAlt || value} />}
      <ChevronDownIcon />
    </>
  )
}

export function ItemComboBoxGenericValuePlaceholder({
  title,
}: {
  title: string
}) {
  return (
    <>
      {title}
      <ElementName />
      <ChevronDownIcon />
    </>
  )
}

function ElementName({ name }: { name?: string }) {
  return (
    <strong className='mx-1 whitespace-nowrap truncate'>{name ?? ''}</strong>
  )
}

const chevronDownIconStyle = {
  width: 14,
  strokeWidth: 2,
  marginTop: 2,
}
export function ChevronDownIcon() {
  return <SvgIcon type={SvgIconType.ChevronDown} style={chevronDownIconStyle} />
}
