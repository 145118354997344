import clsx from 'utils/clsx'
import { DocArticleStyleWrapper } from 'routes/components/MarkdownViewer/DocArticleStyleWrapper'
import { MarkdownViewer } from './MarkdownViewer'
import classes from './MarkdownViewer.module.css'
import { MarkdownToJSX } from 'markdown-to-jsx'

export function DocArticleMarkdownViewer({
  children,
  className,
  options,
}: {
  children: string
  className?: string
  options?: MarkdownToJSX.Options
}) {
  return (
    <DocArticleStyleWrapper className={className}>
      <MarkdownViewer options={options}>{children}</MarkdownViewer>
    </DocArticleStyleWrapper>
  )
}

export function DocEmbeddedMarkdownViewer({
  children,
  className,
  options,
}: {
  children: string
  className?: string
  options?: MarkdownToJSX.Options
}) {
  return (
    <DocArticleMarkdownViewer
      className={clsx(classes.consoleEmbeddedMarkdown, className)}
      options={options}
    >
      {children}
    </DocArticleMarkdownViewer>
  )
}
