/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "API Proxy",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Using API Proxy",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "REST API",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "JavaScript SDK",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Edge Cases",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Headers",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Error Handling",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Limits",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'API Proxy';
export const DESCRIPTION = 'Make requests to the underlying application API when you want to do something custom.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2",
    h3: "h3",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "api-proxy",
      children: "API Proxy"
    }), "\n", _jsxs(_components.p, {
      children: ["API proxy lets you make direct API requests through a ", _jsx(DocLink, {
        path: "engine/deployments/connections",
        children: "Connections"
      }), ".\nAPI Proxy sends your request to the external application without changes and returns the exact response the external app provided."]
    }), "\n", _jsx(_components.p, {
      children: "Proxy API does two things with your requests:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Adds Base URL to the path you provided (unless you provide full URL)"
      }), "\n", _jsx(_components.li, {
        children: "The only thing API proxy does is add authentication headers and handle credential refreshes."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "using-api-proxy",
      children: "Using API Proxy"
    }), "\n", _jsx(_components.h3, {
      id: "rest-api",
      children: "REST API"
    }), "\n", _jsx(_components.p, {
      children: "To make a Proxy request using REST API, simply prefix the request with"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-text",
        children: "https://api.integration.app/connections/<connectionSelector>/proxy\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "Connection selector can be one of the following:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Integration key (if there is only one connection for the integration)"
      }), "\n", _jsx(_components.li, {
        children: "Integration ID (if there is only one connection for the integration)"
      }), "\n", _jsx(_components.li, {
        children: "Connection ID"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Let's say you created a connection to the Hubspot CRM and want to make a request to the following hubspot endpoint:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-text",
        children: "https://api.hubapi.com/crm/v3/owners/\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "you would instead make a request to"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-text",
        children: "https://api.integration.app/connections/<connectionId>/proxy/crm/v3/owners/\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "Or if you don't want to rely on the base URL, you can provide the full URL:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-text",
        children: "https://api.integration.app/connections/<connectionId>/proxy/https://api.hubapi.com/crm/v3/owners/\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "The HTTP method and body you use for this request will be proxied to the underlying application's API."
    }), "\n", _jsx(_components.h2, {
      id: "javascript-sdk",
      children: "JavaScript SDK"
    }), "\n", _jsxs(_components.p, {
      children: ["To make a Proxy request using JavaScript SDK, use ", _jsx(_components.code, {
        children: "proxy"
      }), " property of the Connection:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const response = integrationApp.connection('connection-id').proxy.get('/crm/v3/owners/')\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["You can call ", _jsx(_components.code, {
        children: ".get"
      }), ", ", _jsx(_components.code, {
        children: ".post"
      }), ", ", _jsx(_components.code, {
        children: ".patch"
      }), ", ", _jsx(_components.code, {
        children: ".put"
      }), " and ", _jsx(_components.code, {
        children: ".delete"
      }), " requests of the ", _jsx(_components.code, {
        children: "proxy"
      }), " property.\nFirst parameter is an API path and the second is an optional object with the request payload for ", _jsx(_components.code, {
        children: "post"
      }), ", ", _jsx(_components.code, {
        children: "patch"
      }), ", and ", _jsx(_components.code, {
        children: "put"
      }), " methods."]
    }), "\n", _jsx(_components.h2, {
      id: "edge-cases",
      children: "Edge Cases"
    }), "\n", _jsx(_components.h3, {
      id: "headers",
      children: "Headers"
    }), "\n", _jsx(_components.p, {
      children: "There are special headers that are used by the proxy infrastructure. They will be ignored by the proxy and not passed to the underlying application."
    }), "\n", _jsx(_components.p, {
      children: "Here is the full list of these headers:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "host"
      }), "\n", _jsx(_components.li, {
        children: "x-request-id"
      }), "\n", _jsx(_components.li, {
        children: "x-real-ip"
      }), "\n", _jsx(_components.li, {
        children: "x-forwarded-for"
      }), "\n", _jsx(_components.li, {
        children: "x-forwarded-host"
      }), "\n", _jsx(_components.li, {
        children: "x-forwarded-port"
      }), "\n", _jsx(_components.li, {
        children: "x-forwarded-proto"
      }), "\n", _jsx(_components.li, {
        children: "x-forwarded-scheme"
      }), "\n", _jsx(_components.li, {
        children: "x-scheme"
      }), "\n", _jsx(_components.li, {
        children: "x-original-forwarded-for"
      }), "\n", _jsx(_components.li, {
        children: "x-different-name"
      }), "\n", _jsx(_components.li, {
        children: "x-request-start"
      }), "\n", _jsx(_components.li, {
        children: "x-using-nginx-controller"
      }), "\n", _jsx(_components.li, {
        children: "x-amzn-trace-id"
      }), "\n", _jsx(_components.li, {
        children: "forwarded-for-header"
      }), "\n", _jsx(_components.li, {
        children: "proxy-protocol"
      }), "\n", _jsx(_components.li, {
        children: "real_ip_header"
      }), "\n", _jsx(_components.li, {
        children: "real_ip_recursive"
      }), "\n", _jsx(_components.li, {
        children: "cdn-loop"
      }), "\n", _jsx(_components.li, {
        children: "cf-ipcountry"
      }), "\n", _jsx(_components.li, {
        children: "cf-ray"
      }), "\n", _jsx(_components.li, {
        children: "cf-visitor"
      }), "\n", _jsx(_components.li, {
        children: "cf-connecting-ip"
      }), "\n", _jsx(_components.li, {
        children: "connection"
      }), "\n", _jsx(_components.li, {
        children: "authorization"
      }), "\n", _jsx(_components.li, {
        children: "content-length"
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "error-handling",
      children: "Error Handling"
    }), "\n", _jsx(_components.p, {
      children: "Proxy will return the same status code, headers, and body that the underlying application's API returns. The only exception is handling credential refreshes."
    }), "\n", _jsx(_components.p, {
      children: "If proxy receives a response indicating that the credentials have expired, it will attempt to refresh the credentials once."
    }), "\n", _jsx(_components.p, {
      children: "Here are the possible outcomes when this happens:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "If refresh is successful - it will retry the request and return the second response (successful or not)."
      }), "\n", _jsx(_components.li, {
        children: "If refresh process fails - it will return the original response."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "limits",
      children: "Limits"
    }), "\n", _jsxs(_components.p, {
      children: ["Proxy API has the same limits as the rest of our API endpoints. See ", _jsx(DocLink, {
        path: "engine/limits"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
