/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Flows",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Triggers",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Nodes and Links",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Conditional Links",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Inputs and Outputs",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Node Variables",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Error Handling",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Testing Flows",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Running Flows",
      "attributes": {},
      "children": []
    }]
  }]
}];
import {FLOW_RUNS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const TITLE = 'Flows';
export const DESCRIPTION = 'Building multi-step integration logic';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    code: "code",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components), {DocLink, Link} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "flows",
      children: "Flows"
    }), "\n", _jsx(_components.p, {
      children: "Flows let you build multistep integration logic out of other Integration Elements.\nFlow starts with a Trigger and proceed through a set of Nodes connected by Links.\nBy arranging Triggers, Nodes, and Links, you can build any integration logic you need."
    }), "\n", _jsxs(_components.p, {
      children: ["You apply logic described in a Flow to a ", _jsx(DocLink, {
        path: "engine/deployments/connections",
        children: "Connection"
      }), " by creating and running a Flow Instance."]
    }), "\n", _jsx(_components.h2, {
      id: "triggers",
      children: "Triggers"
    }), "\n", _jsxs(_components.p, {
      children: ["Triggers are special Nodes that launch the flow.\nThey allow you to start flow with an ", _jsx(DocLink, {
        path: "engine/blueprints/flows/nodes/api-trigger",
        children: "API request"
      }), ",\non ", _jsx(DocLink, {
        path: "engine/blueprints/flows/nodes/schedule-trigger",
        children: "Schedule"
      }), ",\nwhen a ", _jsx(DocLink, {
        path: "engine/blueprints/flows/nodes/data-record-created-trigger",
        children: "Data Record is created in an external app"
      }), " ,\nwhen an ", _jsx(DocLink, {
        path: "engine/blueprints/flows/nodes/app-event-trigger",
        children: "Event happens in your app"
      }), ", etc."]
    }), "\n", _jsx(_components.p, {
      children: "Flows can have multiple triggers if you want to reuse the same integration logic in multiple scenarios and don't want to create multiple flows."
    }), "\n", _jsx(_components.h2, {
      id: "nodes-and-links",
      children: "Nodes and Links"
    }), "\n", _jsxs(_components.p, {
      children: ["Each trigger is connected to one or more ", _jsx(DocLink, {
        path: "engine/blueprints/flows/nodes",
        children: "Nodes"
      }), " with Links.\nEach node, in turn, can be connected to one or more downstream nodes. This continues until there are no more nodes and the flow ends."]
    }), "\n", _jsx(_components.p, {
      children: "By default, output of each node (starting with a Trigger) is passed on as Input for each of the following nodes.\nYou can change that by applying filters to Links between nodes using the \"Links\" tab of the node configuration.\nYou can create branching in the flow by sending some outputs to one node and other outputs to another node."
    }), "\n", _jsx(_components.p, {
      children: "Outputs of all the upstream nodes can be used as variables for configuring the downstream nodes."
    }), "\n", _jsx(_components.h2, {
      id: "conditional-links",
      children: "Conditional Links"
    }), "\n", _jsx(_components.p, {
      children: "Links between nodes can be conditional: they can filter which outputs of the previous node they accept and which they do not.\nIf a link filters out an output of the previous node, it does not go further in the flow."
    }), "\n", _jsx(_components.p, {
      children: "This lets you create different types of branching:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "if/else: if condition is met, go one way, if not, go another way"
      }), "\n", _jsx(_components.li, {
        children: "switch: depending on the outputs of the nodes so far, go one way or another"
      }), "\n", _jsx(_components.li, {
        children: "all: run multiple paths of the flow in parallel"
      }), "\n", _jsx(_components.li, {
        children: "on error: run one path if there is no error, another path if there is an error"
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "inputs-and-outputs",
      children: "Inputs and Outputs"
    }), "\n", _jsxs(_components.p, {
      children: ["Flow run is started from a specific trigger that accepts one or more ", _jsx(_components.code, {
        children: "inputs"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["Each input results in a ", _jsx(_components.code, {
        children: "run"
      }), " of a first node in the flow (usually trigger). Output of each node is added to the input of the following nodes under the node's key."]
    }), "\n", _jsx(_components.p, {
      children: "As a result, input for each node looks like this:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-json",
        children: "{\n  ...original flow input,\n  \"<previus-node-key>\": \"<previous node output>\",\n  \"<another-previous-node-key>\": \"<another previous node output>\"\n}\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "node-variables",
      children: "Node Variables"
    }), "\n", _jsx(_components.p, {
      children: "When configuring nodes, you can use the following variables:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "input"
        }), " - combination of all the outputs of the previous nodes. In Triggers, input is data passed from outside the platform when the flow is started."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "flow"
        }), " - information about the curren flow."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "flowInstance"
        }), " - information about the current ", _jsx(DocLink, {
          path: "engine/deployments/flow-instances",
          children: "Flow Instance"
        }), "."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "user"
        }), " - information about the current ", _jsx(DocLink, {
          path: "engine/deployments/customers",
          children: "User"
        }), "."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "error-handling",
      children: "Error Handling"
    }), "\n", _jsx(_components.p, {
      children: "By default, if a node run fails, the output does not go further in the flow.\nIf node had multiple inputs and therefore multiple runs and some of them failed, it does not affect other runs - they will still continue down the flow."
    }), "\n", _jsxs(_components.p, {
      children: ["The default behavior can be changed by setting the node's ", _jsx(_components.code, {
        children: "onError"
      }), " property to ", _jsx(_components.code, {
        children: "continue"
      }), ".\nIn this case, ", _jsx(_components.code, {
        children: "error"
      }), " field containing the error data will be added to variables available for configuring node links."]
    }), "\n", _jsx(_components.p, {
      children: "You can use it like this:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "# flow\nnodes:\n    key: node-that-can-error\n    type: http-api-request\n    config:\n        request:\n            uri: https://non-existing-domain.com\n    onError: continue\n    links:\n        - key: on-success-node\n          filter:\n            $eval:\n                $var: $.error\n            isEmpty: true\n        - key: on-error-node\n          filter:\n            $eval:\n                $var: $.error\n            isNotEmpty: true\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "This node will not stop execution on error, but instead will continue to the next node."
    }), "\n", _jsxs(_components.p, {
      children: ["In this situation, the node run status will be set to ", _jsx(_components.code, {
        children: "completed"
      }), " and it will not be displayed as error in the flow run UI."]
    }), "\n", _jsx(_components.h2, {
      id: "testing-flows",
      children: "Testing Flows"
    }), "\n", _jsx(_components.p, {
      children: "When you build a flow, it is useful to test with real data.\nYou can create or select a Connection and User to test the flow with in the testing panel at the bottom of the screen.\nThen you can provide the input (if needed) and run the flow."
    }), "\n", _jsxs(_components.p, {
      children: ["A ", _jsx(DocLink, {
        path: "engine/deployments/flow-instances",
        children: "Flow Instance"
      }), " will be automatically created or updated to the last flow configuration\nand a ", _jsx(DocLink, {
        path: "engine/activity-log/flow-runs",
        children: "Flow Run"
      }), " will be launched with the selected parameters."]
    }), "\n", _jsx(_components.h3, {
      id: "running-flows",
      children: "Running Flows"
    }), "\n", _jsxs(_components.p, {
      children: ["To launch the flow, you need to deploy a ", _jsx(DocLink, {
        path: "engine/deployments/flow-instances",
        children: "Flow Instance"
      }), " to a customer connection."]
    }), "\n", _jsxs(_components.p, {
      children: ["The log of running flows can be found on the ", _jsx(Link, {
        path: FLOW_RUNS_ROUTE,
        children: "Flow Runs"
      }), " page."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
